@import "../../../_settings.scss";

.seh-field-array {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &__label {
      font-size: 20px;
      flex: 2;
    }

    &__button {
      max-width: fit-content;
    }
  }

  &__subtitle {
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__description {
    margin: 16px 0;
    font-style: italic;
  }

  &__item {
    display: flex;
    padding-left: 32px;

    @media screen and (max-width: 480px) {
      padding-left: 0;
    }

    &__info {
      flex: 1 1;
      display: flex;
      flex-direction: column;

      &__main {
        display: flex;
        margin-bottom: 16px;
        align-items: baseline;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
        }

        &__field {
          flex: 1 1 100%;

          &:not(:last-child) {
            margin-right: 16px;
          }

          @media screen and (max-width: 1024px) {
            margin-right: 0;

            &:not(:last-child) {
              margin-bottom: 16px;
              margin-right: 0;
            }
          }
        }
      }

      &__additional {
        display: flex;
        margin-bottom: 16px;
        align-items: baseline;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        &__field {
          flex: 1 1 100%;

          &:not(:last-child) {
            margin-right: 16px;
          }

          @media screen and (max-width: 768px) {
            margin-right: 0;

            &:not(:last-child) {
              margin-bottom: 16px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  &__dot-number {
    width: 50%;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    &__prefill-btn {
      width: 100px;
      background: transparent;
      border: 1px solid $primary-color;
      padding: 10px;
      color: $primary-color;
      align-self: flex-end;
      margin-top: 10px;
    }
  }
}

.seh-add-button {
  max-height: 42px;
  align-self: flex-end;
}

.on-duty-hours {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  grid-gap: 16px;
  margin-bottom: 16px;

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
  }
}
