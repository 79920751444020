@import '../../../settings';

.select {
  &__option-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 200px;
    & > li:not(li:first-child) {
      border-top: 1px solid $color-gray-d3;
    }
    & > li:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    & > li:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &__option {
      text-decoration: none;
      width: 100%;
      cursor: pointer;
      padding: 8px;
      &:hover {
        background-color: $color-gray-d3;
      }
    }
  }
  &__trigger-container {
    padding: 0;
    margin-top: 0;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__trigger {
      margin: 0;
      height: 42px;
    }
    &--error {
      color: $error-color;

      &__input {
        border: 1px solid $error-color;
        background-color: $color-white-ff;

        &:focus-within {
          border: 1px solid $error-color;
        }
      }
      margin: 0;
    }
    &__svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      transition: transform 0.75s;
      &__rotated {
        transform: rotate(180deg);
      }
      &--error {
        fill: $error-color;
      }
    }
  }
}
.as-placeholder {
  color: $color-gray-d3;
}
