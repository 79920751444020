@import "../../settings";

.submit-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    max-width: 500px;
    border: 2px solid $color-green;
    border-radius: 8px;
    padding: 24px;
    text-align: center;
    box-shadow: 0 8px 16px 0 rgba($color-black, 0.2);

    @media screen and (max-width: 420px) {
      border: none;
      box-shadow: none;
    }

    &__header {
      margin: 0;
      margin-bottom: 16px;
      color: $color-green;
    }

    &__info:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 420px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 776px) {
    padding: 32px;
  }
}
