.rating-form {
  height: 100%;
  &__star-icon {
    color: orange;
  }

  &__agent-rating-box {
    border-bottom: 1.5px solid rgba($color: #011077, $alpha: 0.1);
    padding-bottom: 25px;
  }

  &__button-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__field-heading {
    margin: 0.5rem 0;
  }

  &__rating-box {
    margin-top: 1rem;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    margin-top: 12px;

    h3 {
      color: #011077;
    }
  }

  &__instructor {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 1rem;

    width: 100%;

    margin-top: 12px;

    &__image {
      width: 128px;
      height: 128px;
      border-radius: 50%;

      @media screen and (max-width: 480px) {
        width: 96px;
        height: 96px;
      }
    }

    span {
      color: #011077;
    }

    & > :last-child {
      margin-left: auto;
      grid-column-end: 4;
    }
    & > :first-child {
      margin-right: auto;
    }
  }
}

.rating-header {
  text-align: center;
  color: #011077;
}
