.seh-radio-group {
  height: 100%;

  input {
    display: none;

    &:checked {
      & + svg {
        .inner {
          display: block;
        }
      }
    }
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;

    .inner {
      display: none;
      animation-name: animation-inner;
      animation-duration: 0.35s;
    }

    @keyframes animation-inner {
      0% {
        r: 7;
      }
      50% {
        r: 5.5;
      }
      100% {
        r: 4;
      }
    }
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__option {
      cursor: pointer;
      display: flex;
      width: fit-content;

      label {
        cursor: pointer;
      }

      @media (max-width: 480px) {
        flex-direction: column;
      }
    }
  }
}
