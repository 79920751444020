.open {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1 1 100%;

  &--white {
    .overlay__bg {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  &--black {
    .overlay__bg {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  &--transparent {
    .overlay__bg {
      background-color: transparent;
    }
  }
}
.closed {
  display: none;
}
