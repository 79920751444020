.date-part {
  outline: none;

  &:focus {
    border: 1px #999999 solid;
    border-radius: 4px;
  }
}
.placeholder {
  color: #999999;
}
