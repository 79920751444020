@import '../../../settings';

.seh-multi-select-container {
  width: 100%;
  margin-top: 8px;
  border-radius: 8px !important;
}

.seh-multi-select,
.seh-multi-select-error {
  &__control {
    padding: 0 !important;
    border-radius: 8px !important;
    transition: none !important;

    &--is-focused {
      border: none !important;
      outline: none !important;
      transition: none !important;
    }
  }

  &__value-container {
    padding: 8px !important;
  }

  &__menu {
    margin: 2px 0 !important;
  }

  &__menu-list {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__option {
    color: $color-black !important;
    border-bottom: 1px solid $color-gray-d3;
    background-color: $color-white-ff !important;

    &:hover {
      background-color: $color-gray-d3 !important;
    }
  }
}

.seh-multi-select {
  &__control {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4) !important;
  }
}

.seh-multi-select-error {
  &__control {
    box-shadow: none !important;
    border: 1px solid $error-color !important;
  }
}
