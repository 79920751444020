.footer-container {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  background: linear-gradient(
    0deg,
    rgba(69, 99, 253, 0.5) 0%,
    transparent 100%
  );
  @media (max-width: 640px) {
    flex-direction: column;
  }
  padding: 20px 0;

  &__image-container {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &__image {
      object-fit: cover;
      width: 150px;
    }
  }
  &__content {
    height: 100%;
    display: flex;
    flex: 1 1 100%;
    align-items: flex-start;
    justify-content: space-evenly;
    @media (max-width: 640px) {
      flex-direction: column;
      flex: 1 1 auto;
      // align-items: center;
      width: fit-content;
    }
    &__segment {
      display: flex;
      flex-direction: column;
      max-width: 350px;
      &__segment-header {
        color: #011077 !important;
      }
      &__name-header {
        margin-bottom: 0;
      }
      &__email-label {
        font-style: italic;
      }
    }
  }
}
