@import "../../settings";

.employment-history-form {
  &__header {
    margin-left: 32px;
  }
  &__sub-header {
    color: $primary-color;
  }

  &__fill-the-form {
    color: $secondary-color;
    text-decoration: underline;
    font-size: 18px;

    margin-left: 32px;
    cursor: pointer;
  }

  &__preview-document {
    color: $primary-color;
    font-size: 14px;
    font-style: italic;

    margin: 0 0 0 32px;
  }
  .seh-radio-group__container {
    flex-direction: row;
    margin-top: 10px;
  }

  &__checkbox-group {
    margin: 12px 0 20px;
  }

  &__divider {
    margin: 12px 0;
  }

  &__pdf-file {
    border: 0;
    height: 100vh;
  }
  &__container {
    padding: 32px;
  }
}
