@import "../../../settings";

.checkbox {
  &__options {
    display: grid;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    row-gap: 16px;
    margin-top: 12px;

    & > * {
      margin-left: auto;
    }

    label {
      cursor: pointer;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__error {
    margin-top: 12px;
    color: $error-color;
  }
}
