@import '../../../_settings.scss';

.hourpicker-part-container {
  width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  overflow: hidden;
  &-error {
    border: 1px solid $color-red;
  }
  &__arrow-container {
    display: flex;
    flex: 1 1 25%;
    justify-content: center;
    align-items: center;
    transform-origin: 50% 25%;
    width: 100%;
    background: linear-gradient(90deg, #011077 0%, #4563fd 100%);

    &__arrow {
      width: 15px;
      height: 15px;
      fill: white;
      &__down {
      }
      &__up {
        transform: rotate(180deg);
      }
    }
  }
  &__main {
    display: flex;
    flex: 1 1 50%;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    justify-content: center;
    background-color: $color-white-ff;
  }
}
