@import "../../../settings";

.seh-checkbox {
  display: flex;

  &__label {
    margin-right: 8px;
    white-space: nowrap;
  }

  input {
    width: 21px;
    height: 21px;
    position: relative;
    cursor: pointer;
    border: 2px solid $primary-color;
    border-radius: 3px;
    appearance: none;
    background-color: $color-white-ff;
    margin: 0;
    margin-right: 18px;
    font: inherit;
    display: grid;
    place-content: center;

    &::before {
      width: 11px;
      height: 11px;

      content: "";
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-image: url("../../../svg/checkmark.svg");
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}
