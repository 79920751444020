@import '../../../../settings';

.datepicker-menu-container {
  width: fit-content;
  user-select: none;

  &__main {
    display: grid;
    &__field {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 2px;

      &--invalid {
        color: $color-gray-99;
        cursor: default;
        text-decoration: line-through;
      }

      &--valid {
        cursor: pointer;

        &:hover {
          cursor: pointer;
          background: rgba($primary-color, 0.25);
          color: $primary-color;
        }
      }

      &--different-month {
        color: $color-gray-99;
      }

      &--selected {
        color: $color-white-ff;
        background-color: $primary-color;
      }

      &--current-month {
        color: $color-gray-33;
      }
    }
  }
}
