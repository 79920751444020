.dropdown {
  width: fit-content;
  width: 100%;
  position: relative;
  z-index: 1;

  &--open {
    z-index: 5;
  }

  .overlay {
    z-index: -1;
  }

  &__content {
    border: 1px solid grey;
    border-radius: 4px;
    width: fit-content;
    padding: 0;
    margin-top: 8px;
    position: absolute;

    &-full-width {
      width: 100%;
    }
    &-sticked-to-trigger {
      margin-top: 0;
    }
    &--white {
      background-color: rgba(255, 255, 255, 1);
    }
    &--black {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
    }
    &--transparent {
      background-color: transparent;
    }

    &--upwards {
      transform: translateY(-100%);
    }
    &--backwards {
      transform: translateX(-100%);
    }
  }

  &__trigger {
    width: 100%;
    & > button {
      cursor: pointer;
    }

    &--open {
    }
  }
}
