@import "../../../_settings.scss";

.progression {
  display: flex;
  flex-direction: column;
  color: $form-progression-color;
  width: 100%;
  margin-bottom: 20px;

  .progression {
    &__bar {
      background-color: $form-progression-color;
      border-radius: 11px;
      height: 14px;
      margin-bottom: 12px;

      &__filler {
        border-radius: 11px;
        height: 14px;
        background: linear-gradient(
          90deg,
          $primary-color 0%,
          $secondary-color 100%
        );

        transition: width 0.25s ease-in-out;
        animation-name: fadeOutLeft;
        animation-duration: 0.3s;

        @keyframes fadeOutLeft {
          from {
            opacity: 1;
          }

          to {
            opacity: 0;
          }
        }
      }
    }

    &__stages {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &__stage {
        position: relative;
        width: 100%;
        padding-right: 5%;

        &--disabled {
          color: $primary-color;
        }

        &:last-of-type {
          @media (max-width: 620px) {
            width: fit-content;
            padding-right: 2px;
          }
        }

        &__circle {

          &_icon{
            position: absolute;
            top: -28px;
            left: 2px;
  
          }
          position: absolute;
          top: -24px;
          left: 2px;

          &__empty {
            height: 10px;
            width: 10px;
            border: 0.5px solid $primary-color;
            background-color: $color-white-ff;
            border-radius: 50%;
          }
        }

        &__label {
          text-transform: uppercase;
          font-weight: bold;

          &__text {
            @media (max-width: 620px) {
              display: none;
            }
          }
        }

        &__name {
          hyphens: auto;
          @media (max-width: 620px) {
            display: none;
          }
        }
      }
    }
  }
}

