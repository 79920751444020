.hourpicker-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  @media (max-width: 850px) {
    width: 100%;
    justify-content: space-evenly;
  }
  & > * + * {
    margin-left: 16px;
  }
  &__datepicker {
    flex: 1 1 100%;
  }
  &__hourpicker {
    flex: 1 1 100%;
  }
}
