@import "../../../_settings.scss";

.star-rating-input {
  height: "20px";
  &--error {
    color: $error-color;
  }
  &__stars-box {
    display: flex;
  }
  &__stars-box:hover {
    cursor: pointer;
  }
  &__error-message {
    color: $error-color;
  }
}
