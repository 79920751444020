@import '../../../settings';

.seh-action-button {
  border: 1px solid $color-green;
  background-color: transparent;
  color: $color-green;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  padding: 12px;
  font-family: inherit;

  &--remove {
    color: $color-red;
    border: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &--disabled {
    background-color: $color-gray-b2;
    color: $color-gray-44;
    border-color: transparent;

    &:hover {
      cursor: default;
    }
  }
}
