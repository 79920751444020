@import "../../../settings";

textarea {
  box-sizing: border-box;
  min-height: 80px;
  padding: 12px;
  resize: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;

  @media screen and (max-width: 350px) {
    min-height: 60px;
    font-size: 12px;
  }
}
