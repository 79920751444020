.motor-vehicle-accidents {
  &__brief-description {
    margin-top: 12px;
  }

  &__button-wrapper {
    display: flex;
    width: 100%;

    &__button {
      margin: 12px 0 0 auto;
    }
  }

  &__checkbox-group {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
  }
}
