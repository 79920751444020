$primary-color: #011077;
$secondary-color: #4563fd;
$form-progression-color: #c8d6ff;
$error-color: #db2828;
$warning-color: #b58105;
$color-gray-33: #333333;
$color-gray-44: #444444;
$color-gray-b2: #b2b2b2;
$color-gray-d3: #d3d3d3;
$color-gray-99: #999999;
$color-white-ff: #fff;
$color-white-f2: #f2f2f2;
$color-black: #000;
$color-green: #32cd32;
$color-red: #db2828;
$color-yellow: #ffff00;
