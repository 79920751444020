@import "_settings.scss";

html,
body,
#root {
  height: 100%;
}

.app-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.seh-app {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  @media screen and (max-width: 1400px) {
    width: 60%;
  }

  @media screen and (max-width: 1024px) {
    width: 80%;
  }

  @media screen and (max-width: 480px) {
    width: 92%;
  }

  @media screen and (max-width: 350px) {
    width: 100%;
  }
}
.label-container {
  margin-bottom: 8px;
  &__label {
    &--error,
    &__star-required {
      color: $error-color;
    }
  }
}
.seh-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  outline: none;

  &__error {
    margin-top: 4px;
    color: $color-red;
  }
}
.seh-form-template {
  position: relative;
  border: 2px solid $color-white-f2;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  > form {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
  }

  &--submition {
    border: none;
    background: $color-white-ff;
    padding: 32px 0;
  }
}

.seh-button-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;

  > button {
    max-width: 180px;
  }
}

.seh-form-container {
  &__paragraph {
    font-size: 18px;
  }
  &__content {
    display: flex;
    flex-direction: column;

    &__group {
      margin-bottom: 36px;
      display: flex;
      align-items: baseline;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        margin-bottom: 0px;
      }

      &__field {
        flex: 1 1 100%;
        margin-right: 36px;
        background-color: transparent;
        label {
          font-weight: 700;
        }
        &--1 {
          flex: 0 1 50%;

          @media screen and (max-width: 1024px) {
            width: 100%;
            margin-bottom: 16px;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        @media screen and (max-width: 1024px) {
          margin-bottom: 16px;
          margin-right: 0;

          &:last-child {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
    &--2-years-of-experience {
      padding: 16px;
      border: 4px solid $secondary-color;
      border-radius: 8px;
    }
  }
}

.seh-info-container {
  margin: 16px 0;
}

.seh-info,
.seh-additional-info {
  font-style: italic;
  margin: 0;
}

.seh-additional-info {
  padding-left: 16px;
  margin-top: 8px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-gray-33;
  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
}

h2 {
  color: $primary-color;
  text-transform: capitalize;
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 350px) {
    font-size: 16px;
  }
}

.seh-spinner-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.seh-spinner-msg {
  color: $primary-color;
  font-size: 20px;
}

.seh-loader {
  color: $primary-color;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  background-color: rgba($color-white-ff, 0.7);
}

.submit-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
}

.date-of-application {
  display: flex;

  > :first-child {
    font-weight: bold;
  }

  > :not(:first-child) {
    margin-left: 8px;
  }

  @media screen and (max-width: 620px) {
    flex-direction: column;

    > :not(:first-child) {
      margin-left: 0;
      margin-top: 8px;
    }
  }
}
