@import '../../../settings';

.seh-button {
  display: inline-block;
  font-size: 16px;
  padding: 16px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 8px;
  font-weight: 700;
  letter-spacing: 1px;
  outline: none;
  font-family: inherit;

  &:disabled {
    background: none;
    background-color: $color-gray-b2;
    color: $color-gray-44;

    &:hover {
      cursor: default;
    }
  }

  &--primary {
    background: linear-gradient(
      90deg,
      $primary-color 0%,
      $secondary-color 100%
    );
    color: $color-white-ff;
  }

  &--secondary {
    border: none;
    background: none;
    background-color: transparent;
    color: $primary-color;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  &--s {
    max-width: 160px;
  }

  &--m {
    max-width: 240px;
  }

  &--l {
    max-width: 360px;
  }

  &--xl {
    max-width: 480px;
  }
}
