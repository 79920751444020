@import '../../../settings';

.input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

.seh-container {
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  &__input {
    margin-top: 8px;
    width: 100%;
    min-width: 80px;
    border: 1px solid $color-gray-b2;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    transition: border 0.3s ease-in-out;
    background-color: white;

    &:focus-within {
      border: 1px solid $color-yellow;
    }

    > input {
      width: 100%;
      padding: 12px;
      font-size: 16px;
      box-sizing: border-box;
      width: 100%;
      border: none;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }

  &--error {
    color: $error-color;

    &__input {
      border: 1px solid $error-color;
      background-color: $color-white-ff;

      &:focus-within {
        border: 1px solid $error-color;
      }
    }
  }

  &--disabled {
    color: $color-gray-44;
  }
}

.seh-required {
  color: $color-red;
}
