@import '/src/settings';

.header {
  background: linear-gradient(90deg, $primary-color 0%, $secondary-color 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $color-white-ff;
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &__heading {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    cursor: pointer;

    &__rotated {
      transform: rotate(180deg);
    }

    & svg {
      transition: transform 0.25s;
      fill: $color-white-ff;
    }

    & p {
      margin: 0;
      margin-right: 8px;
    }
  }

  &__arrow {
    fill: $color-white-ff;
    height: 18px;
    cursor: pointer;
  }
}
