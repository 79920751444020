@import '../../../settings';

.seh-toggle-button {
  display: flex;
  flex-direction: column;
  outline: none;

  &__switch {
    margin-top: 8px;
    width: 124px;
    position: relative;
    border: 1px solid $primary-color;
    border-radius: 8px;
    background-color: $color-white-ff;
    color: $primary-color;
    display: flex;
    cursor: pointer;
    input {
      display: none;
    }

    &__choice {
      text-align: center;
      flex: 1 1 50%;
      z-index: 2;
      padding: 12px 0px;
      transition: color 0.4s ease;
      font-weight: 700;
      user-select: none;

      &--checked {
        color: $color-white-ff;
      }
    }

    &__slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      transition: 0.4s;
      border-radius: 6px;
      background-color: $primary-color;

      &:before {
        content: '';
        transition: 0.4s;
      }
    }

    input:checked + &__slider {
      transform: translateX(100%);
    }
  }
}

.seh-required {
  color: $color-red;
}
